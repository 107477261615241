<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Login Area Component -->
  <login-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import LoginArea from "../../components/LoginArea";
export default {
  name: "Login",
  components: {LoginArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Login",
        pageTitle: "Login"
      }
    }
  }
}
</script>

<style scoped>

</style>