<template>
  <!-- Login Page Area Start -->
  <section class="ekifa-login-page-area section_80">
        <b-container>
          <b-row>
            <b-col xl="4" lg="6" md="8" offset-xl="4" offset-lg="3" offset-md="2">
              <div class="login-box">
                <h3>login to your account</h3>
                <b-form>
                  <b-form-group
                      id="input-group-1"
                      label-for="input-1"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="login.email"
                        type="email"
                        placeholder="E-mail Address"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                      id="input-group-2"
                      label-for="input-2"
                  >
                    <b-form-input
                        id="input-2"
                        v-model="login.password"
                        type="password"
                        placeholder="Password"
                        required
                    ></b-form-input>
                  </b-form-group>
                  <b-button type="submit" >sign in</b-button>
                  <div class="login-grid">
                    <p>
                      Forget password? Please <b-link>click</b-link>
                    </p>
                    <p>create <b-link>new account</b-link></p>
                  </div>
                </b-form>
              </div>
              <div class="social-login">
                <h3>or</h3>
                <ul>
                  <li class="facebook"><b-link><i class="fa fa-facebook"></i></b-link></li>
                  <li class="twitter"><b-link><i class="fa fa-twitter"></i></b-link></li>
                  <li class="google-plus"><b-link><i class="fa fa-google-plus"></i></b-link></li>
                  <li class="linkedin"><b-link><i class="fa fa-linkedin"></i></b-link></li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-container>
  </section>
  <!-- Login Page Area End -->
</template>

<script>
export default {
  name: "LoginArea",
  data() {
    return {
      login: {
        email: '',
        password: ''
      }
    }
  }
}
</script>

<style scoped>

</style>